<!--
 * @Author: xiaziwen
 * @Date: 2021-01-13 11:56:58
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-13 14:02:25
 * @FilePath: \crm-education\src\views\teacher\test\index.vue
-->
<template>
  <div>
    <a-card class="cus-card no-border" :bordered="false">
      <template slot="title">
        <div
          class="section-header dec d-flex align-center justify-between flex-wrap"
        >
          <h2 class="base-title fs-18">作业</h2>
          <div class="header-right d-flex align-center">
            <p class="fs-14 color-blue link plus ml-20">创建作业</p>
            <p class="fs-14 color-blue link zyk  ml-20">作业库</p>
            <p class="fs-14 color-gray link delete  ml-20">回收站</p>
          </div>
        </div>
      </template>
       <div class="py-5 d-flex">
        <a-select v-model="selectKey">
          <a-select-option value="1">一班</a-select-option>
          <a-select-option value="2">二班</a-select-option>
        </a-select>
      </div>
      <a-list item-layout="horizontal" :data-source="dataList" :split="false">
          <a-list-item slot="renderItem" slot-scope="item, index">
            <teacher-test-item :item="item" :index="index"></teacher-test-item>
          </a-list-item>
        </a-list>
    </a-card>
  </div>
</template>

<script>
import TeacherTestItem from './c/TeacherTestItem'
export default {
  name: 'teacher_test',
  components: {
    TeacherTestItem
  },
  data () {
    return {
      selectKey: '1',
      dataList: [{}, {}]
    }
  }
}
</script>